.notes-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  height: 34px;
  width: fit-content;
  padding: 5px 8px 5px 10px;
  background: #f9f9f9;
  min-width: 5.875rem;

  .content {
    color: #1f93ef;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;
  }
  
  &.delete-note {
    background: #FFF5F5;
    
    .delete-content {
      color: #FF5252;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.disabled-note-action {
  cursor: not-allowed;
}
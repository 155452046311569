.custom-button-default {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.38rem 0.75rem;
  border: 1px solid #1f93ef;
  background: #1f93ef;
  color: white;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s, color 0.3s;
  font-size: 0.75rem;
  font-weight: 500;

  &:hover {
    background-color: #46abff;
  }

  &:active {
    background-color: #64b5f6;
  }

  height: 2.5rem;
}

.custom-button-disable {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.38rem 0.75rem;
  border: 1px solid #1f93ef;
  background-color: #64b5f6;
  color: white;
  cursor: not-allowed;
  border-radius: 3px;
  font-size: 0.75rem;
  font-weight: 500;
  height: 2.5rem;
}

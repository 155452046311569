.MuiBox-root::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  scrollbar-gutter: stable;
}

.MuiBox-root:hover::-webkit-scrollbar {
  width: 8px;
  scrollbar-gutter: stable;
}
.MuiBox-root:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  scrollbar-gutter: stable;
}

.MuiModal-root {
  .MuiBox-root {
    border: none;
    outline: none;
  }
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.custom-editor-container {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  font-family: "Arial", sans-serif;
}

.ql-toolbar {
  background: transparent;
  // border-bottom: 1px solid #ddd;
}

.ql-editor {
  min-height: 200px;
  font-size: 14px;
  line-height: 1.6;
  padding: 20px;
}

.word-counter {
  text-align: right;
  font-size: 12px;
  color: #555;
  margin-top: 10px;
}

.ql-container {
  border: none;
  background: transparent;
}

.ql-toolbar button {
  border: none;
  background: transparent;
  margin: 0 5px;
}

.ql-toolbar .ql-active {
  color: #333;
}

.ql-toolbar.ql-snow {
  border: none !important;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-toolbar .ql-picker-label {
  padding-left: 5px;
}

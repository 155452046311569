.tab-container {
  display: flex;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  justify-content: space-between;
  padding: 15px 14px;
  gap: 14px;
  cursor: pointer;
  background-color: white;
  &.inactive {
    background-color: whitesmoke;
  }

  .titlePara{
    font-size: 13px;
  }

  .tab-item-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: max-content;
    gap: 14px;
  }

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }
}

.disabledBackground {
  background-color: #e2e2ea;
}

.cardContainer {
  &:not(.disabledBackground) {
    background-color: #F9F9F9;
  }
  min-width: 270px;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  padding: 16px;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  // max-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .checkBoxContainer {
    display: flex;
    justify-content: flex-end;

    .checkbox {
      background-color: black;
      width: 20px;
      height: 20px;
    }
  }

  .cardDecription {
    color: #7d7d7d;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 21px;
    width: 98%;
    margin-top: 16px;
    margin-bottom: 16px !important;
    overflow-y: auto;
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 2px;
      scrollbar-gutter: stable;
    }
    &:hover {
      scrollbar-gutter: stable;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  .sub {
    display: flex;
    gap: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    .titleContainerCat {
      flex: 1;
      .subCategory {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        width: 250px;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        color: "#16161E";
      }
      .subLabelCard {
        font-size: 12px;
        color: rgba(22, 22, 30, 0.70);
        margin: 5px 0px 5px 0px !important;
        text-transform: capitalize;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        width: 240px;
      }
    }
  }
  .description {
    .groupDownload {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 100%;
      .downloadIconContainer {
        align-items: center;
        border-radius: 4px;
        display: flex;
        height: 32px;
        justify-content: center;
        padding: 20px 20px;
        width: 32px;
        transition: 200ms;
        &:not(.disabledDownloadBtn) {
          border: 1px solid #1f93ef;
          img {
            filter: invert(39%) sepia(91%) saturate(2232%) hue-rotate(191deg) brightness(107%) contrast(87%);
          }
        }
        &:not(.disabledDownloadBtn):hover {
          background-color: #1f93ef;
          transition: 200ms;
          cursor: pointer;
          img {
            filter: invert(1);
          }
        }
      }
      .disabledDownloadBtn {
        background: #c6cbd9;
      }
      .frame-wrapper {
        width: 100%;
        .scopeContainer-2 {
          align-items: center;
          display: flex;
          flex: 1;
          flex-grow: 1;
          gap: 8px;
          justify-content: start;
        }
        .addData {
          width: 100% !important;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .cardContainer {
    width: 100%;
  }
}

.measure-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: white;
  // position: relative;

  .cardContentContainer {
    height: 65vh;
    overflow: auto;
  }
  .measureCardContainer {
    width: 100%;
    overflow: auto;
    flex: 1 1 auto;
    .scopeDesc {
      margin: 20px 0px 20px 0px;
      .scopeDescriptionPara {
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 600;
      }
      .descriptionOfScope {
        font-size: 12px;
      }
    }
    .cardsContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }
  }
}

.brsr-note-container {
  position: relative;
  margin-bottom: 40px;

  .note-content-wrapper {
    position: relative;

    .quill {
      .ql-container {
        height: auto;
        
        .ql-editor {
          min-height: 50px;
          height: auto;
          max-height: none;
          overflow: visible;
        }
      }
    }

    .note-actions {
      display: flex;
      gap: 8px;
      position: absolute;
      right: 10px;
      transform: translateY(50%);
      z-index: 10;
    }
  }

  .add-note-button {
    margin-top: 10px;
  }

  .note-info-container {
    display: flex;
    gap: 8px;
    align-items: center;

    .note-info-item {
      color: #16161e;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }

    .note-info-sub-item {
      display: flex;
      align-items: center;
      gap: 4px;

      .subtitle-2 {
        color: rgba(22, 22, 30, 0.7);
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }

      .subtitle-3 {
        color: rgba(0, 0, 0, 0.35);
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }
    }
  }

  .inline-editor-container {
    margin-bottom: 20px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .subtitle-1 {
      display: flex;
      align-items: center;
      gap: 5px;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.9);
    }

    .editor-actions {
      display: flex;
      justify-content: flex-start;
    }
  }

  .out-of-limit-text {
    margin-top: -0.75rem;
    color: #ff535b;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}

.notes-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  height: 34px;
  width: fit-content;
  padding: 5px 8px 5px 10px;
  background: #f9f9f9;
  border-radius: 4px;

  .content {
    color: #1f93ef;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }
}

.notes-modal-container {
  width: 100%;
  max-width: 600px;

  .notes-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h4 {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      font-family: poppins;
    }

    .close-button {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .notes-editor-container {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .subtitle-1 {
      font-size: 14px;
      font-weight: 400;
      font-family: poppins;
    }
  }

  .notes-modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    .cancel-button {
      background-color: #f5f5f5;
      border: 1px solid #ddd;
    }

    .submit-button {
      min-width: 100px;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.note-history-drawer {
  .custom-drawer {
    margin-top: unset;
  }

  .close-btn {
    display: none;
  }

  .ant-select-selector {
    border-radius: 2px !important;
  }

  .select-facility-text {
    display: flex;
    align-items: center;
    gap: 5px;
    color: rgba(0, 0, 0, 0.60);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .note-history-title {
    color: #16161e;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  .note-msg-container {
    overflow: auto;
    
    &::-webkit-scrollbar {
      background-color: transparent;
      height: 8px;
      scrollbar-gutter: stable;
      width: 5px;
    }
    &:hover::-webkit-scrollbar {
      height: 8px;
      scrollbar-gutter: stable;
      width: 5px;
    }
    &:hover::-webkit-scrollbar-thumb {
      background-color: #00000033;
      scrollbar-gutter: stable;
    }
  }

  .profile-section {
    margin-bottom: 10px;

    .profile-name {
      color: #16161e;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }

    .profile-role {
      color: rgba(22, 22, 30, 0.7);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    .profile-role-time {
      color: rgba(0, 0, 0, 0.35);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }

  .msg-container {
    margin-bottom: 20px;
    max-width: 328px;
    min-width: 328px;

    .quill {
      .ql-container {
        height: auto;
        background: #f9f9f9;

        .ql-editor {
          padding: 8px;
          min-height: auto;
          height: auto;
          max-height: none;
          overflow: visible;
        }
      }
    }
  }

  .empty-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
}
